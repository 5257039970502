var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container pb-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8 offset-2"},[_vm._m(0),_c('hr',{staticClass:"mt-3 mb-3"}),_vm._m(1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.salvar)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Tipo da chave PIX")]),_c('ValidationProvider',{attrs:{"name":"tipo da chave pix","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.dados.tipoChave),expression:"dados.tipoChave"}],staticClass:"form-control",class:classes,on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.dados, "tipoChave", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.limpar]}},[_c('option',{attrs:{"selected":""},domProps:{"value":null}},[_vm._v("Selecione...")]),_c('option',{attrs:{"value":"CPF"}},[_vm._v("CPF")]),_c('option',{attrs:{"value":"CNPJ"}},[_vm._v("CNPJ")]),_c('option',{attrs:{"value":"EMAIL"}},[_vm._v("Email")]),_c('option',{attrs:{"value":"TELEFONE"}},[_vm._v("Telefone")]),_c('option',{attrs:{"value":"CHAVE-ALEATORIA"}},[_vm._v("Chave Aleatória")])]),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-6"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dados.tipoChave),expression:"dados.tipoChave"}],staticClass:"form-group"},[_c('label',[_vm._v("Chave Pix")]),(_vm.dados.tipoChave == 'CPF')?_c('ValidationProvider',{attrs:{"name":"chave pix CPF","rules":"required|cpf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dados.cpf),expression:"dados.cpf"},{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],staticClass:"form-control",class:classes,attrs:{"type":"text","placeholder":"Chave pix CPF"},domProps:{"value":(_vm.dados.cpf)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dados, "cpf", $event.target.value)}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),(_vm.dados.tipoChave == 'CNPJ')?_c('ValidationProvider',{attrs:{"name":"chave pix cnpj","rules":"required|cnpj"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dados.cnpj),expression:"dados.cnpj"},{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"}],staticClass:"form-control",class:classes,attrs:{"type":"text","disabled":""},domProps:{"value":(_vm.dados.cnpj)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dados, "cnpj", $event.target.value)}}})]}}],null,true)}):_vm._e(),(_vm.dados.tipoChave == 'EMAIL')?_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dados.email),expression:"dados.email"},{name:"mask",rawName:"v-mask",value:(_vm.dados.email),expression:"dados.email"}],staticClass:"form-control",class:classes,attrs:{"type":"text","placeholder":"Chave pix email"},domProps:{"value":(_vm.dados.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dados, "email", $event.target.value)}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),(_vm.dados.tipoChave == 'TELEFONE')?_c('ValidationProvider',{attrs:{"name":"telefone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dados.telefone),expression:"dados.telefone"},{name:"mask",rawName:"v-mask",value:('(##) #####-####'),expression:"'(##) #####-####'"}],staticClass:"form-control",class:classes,attrs:{"type":"tel","placeholder":"Chave pix Telefone"},domProps:{"value":(_vm.dados.telefone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dados, "telefone", $event.target.value)}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),(_vm.dados.tipoChave == 'CHAVE-ALEATORIA')?_c('ValidationProvider',{attrs:{"name":"chave pix aleatória","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dados.aleatoria),expression:"dados.aleatoria"},{name:"mask",rawName:"v-mask",value:(_vm.dados.cpf),expression:"dados.cpf"}],staticClass:"form-control",class:classes,attrs:{"type":"text","placeholder":"Chave pix aleatória"},domProps:{"value":(_vm.dados.aleatoria)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dados, "aleatoria", $event.target.value)}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e()],1)]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Agência")]),_c('ValidationProvider',{attrs:{"name":"agência","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dados.agencia),expression:"dados.agencia"}],staticClass:"form-control",class:classes,attrs:{"type":"text","placeholder":"Agência"},domProps:{"value":(_vm.dados.agencia)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dados, "agencia", $event.target.value)}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Conta")]),_c('ValidationProvider',{attrs:{"name":"conta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dados.conta),expression:"dados.conta"}],staticClass:"form-control",class:classes,attrs:{"type":"text","placeholder":"Conta"},domProps:{"value":(_vm.dados.conta)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dados, "conta", $event.target.value)}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Nome do titular")]),_c('ValidationProvider',{attrs:{"name":"nome do titular","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dados.nomeTitular),expression:"dados.nomeTitular"}],staticClass:"form-control",class:classes,attrs:{"type":"text","placeholder":"Nome titular"},domProps:{"value":(_vm.dados.nomeTitular)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dados, "nomeTitular", $event.target.value)}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('hr',{staticClass:"mt-3 mb-3"}),_c('button',{staticClass:"btn btn-success btn-block",attrs:{"type":"submit","disabled":_vm.loading || invalid}},[(_vm.loading)?_c('span',[_vm._v("Salvando dados bancarios...")]):_c('span',[_vm._v("Salvar dados bancários")])])])]}}])})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h5',{staticClass:"font-weight-light"},[_c('b',[_vm._v(" Dados bancários ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"alert alert-info"},[_c('p',[_vm._v(" Precisamos dos seus dados bancários, para nosso financeiro efetuar o pagamento via pix da comissão dos seguros vendidos, aprovados e pagos pelos clientes. ")]),_c('p',[_c('b',[_vm._v(" ATENÇÃO: ")]),_vm._v(" seus dados bancários, devem estar corretamente informados para evitar problemas de pagamento. caso tenha dúvidas favor enviar um email para "),_c('a',{attrs:{"href":"mailto:samarone@bikeregistrada.com.br"}},[_vm._v("samarone@bikeregistrada.com.br")])])])}]

export { render, staticRenderFns }